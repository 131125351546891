"use client";

import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useTranslations } from "next-intl";

import { Button } from "@vrodex/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@vrodex/ui/dialog";

import { usePlatform } from "~/hooks/use-platform";

export function UpdateProvider(props: { children: ReactNode }) {
  const platform = usePlatform();

  const t = useTranslations("UpdateDialog");

  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    if (platform === "DESKTOP") {
      window.vrodex.checkForClientUpdate().catch((err) => console.error(err));
      window.vrodex.onUpdateAvailable(() => {
        setUpdateAvailable(true);
      });
    }
  }, [platform]);

  return (
    <>
      {props.children}
      <Dialog
        open={updateAvailable}
        onOpenChange={(open) => setUpdateAvailable(open)}
      >
        <DialogContent
          className="max-w-lg"
          onInteractOutside={(e) => e.preventDefault()}
        >
          <DialogHeader>
            <DialogTitle>{t("title")}</DialogTitle>
            <DialogDescription>{t("description")}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => window.vrodex.installUpdate()}
              variant="success"
            >
              {t("update_now_button_label")}
            </Button>
            <Button
              onClick={() => setUpdateAvailable(false)}
              variant="destructive"
            >
              {t("update_later_button_label")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
