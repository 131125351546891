import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ILoadingStore {
  settingsNavOpen: boolean;
  setSettingsNavOpen: (settingsNavOpen: boolean) => void;
  mobileNavOpen: boolean;
  setMobileNavOpen: (mobileNavOpen: boolean) => void;
  settingsSearch: string;
  setSettingsSearch: (settingsSearch: string) => void;
}

export const useSessionStore = create<ILoadingStore>()(
  devtools((set) => ({
    settingsSearch: "",
    setSettingsSearch: (settingsSearch) => set({ settingsSearch }),
    mobileNavOpen: false,
    settingsNavOpen: false,
    setSettingsNavOpen: (settingsNavOpen) => set({ settingsNavOpen }),
    setMobileNavOpen: (mobileNavOpen) => set({ mobileNavOpen }),
  })),
);
