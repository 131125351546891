import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";

import { usePreferencesStore } from "./use-preferences-store";

const PLATFORMS = {
  DESKTOP: "desktop",
  WEB: "web",
} as const;

type Platform = keyof typeof PLATFORMS;

function isVersionHigher(version: string, targetVersion: string): boolean {
  const versionParts: number[] = version.split(".").map(Number);
  const targetVersionParts: number[] = targetVersion.split(".").map(Number);

  for (
    let i = 0;
    i < Math.max(versionParts.length, targetVersionParts.length);
    i++
  ) {
    const versionPart: number = versionParts[i] ?? 0;
    const targetVersionPart: number = targetVersionParts[i] ?? 0;

    if (versionPart > targetVersionPart) {
      return true;
    } else if (versionPart < targetVersionPart) {
      return false;
    }
  }

  return false; // versions are equal
}

export const usePlatform = () => {
  const [platform, setPlatform] = useState<Platform>();
  const { fullScreen, version, setVersion } = usePreferencesStore(
    useShallow((state) => ({
      fullScreen: state.fullScreen,
      version: state.version,
      setVersion: state.setVersion,
    })),
  );

  useEffect(() => {
    if (window.vrodex?.getVersion) {
      const getVersion = async () => {
        const tempVersion = await window.vrodex.getVersion();

        setVersion(tempVersion);
      };
      getVersion().catch((err) => console.warn(err));
    }
  }, [setVersion]);

  useEffect(() => {
    if (window.vrodex) {
      setPlatform("DESKTOP");
      if (isVersionHigher(version, "1.0.9")) {
        if (fullScreen) {
          if (window.vrodex.enterFullScreen) {
            void window.vrodex.enterFullScreen();
          }
        } else {
          if (window.vrodex.exitFullScreen) {
            void window.vrodex.exitFullScreen();
          }
        }
      }
    } else {
      setPlatform("WEB");
    }
  }, [setPlatform, fullScreen, version]);

  return platform;
};
