import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type { Themes } from "@vrodex/validators";

interface AppPreferences {
  homePage: string;
  idleTimer: number;
  setIdleTimer: (idleTimer: number) => void;
  setHomePage: (homePage: string) => void;
  fullScreen: boolean;
  setFullScreen: (fullScreen: boolean) => void;
  version: string;
  setVersion: (version: string) => void;
  theme?: Themes;
  setTheme: (theme?: Themes) => void;
}

export const usePreferencesStore = create<AppPreferences>()(
  devtools(
    persist(
      (set) => ({
        idleTimer: 300,
        setIdleTimer(idleTimer) {
          set({ idleTimer });
        },
        homePage: "dashboard",
        setTheme(theme) {
          set({ theme });
        },
        fullScreen: false,
        version: "",
        setVersion(version) {
          set({ version });
        },
        setFullScreen(fullScreen) {
          set({ fullScreen });
        },
        setHomePage(homePage) {
          set({ homePage });
        },
      }),
      {
        name: "preferences-storage",
      },
    ),
  ),
);
