import(/* webpackMode: "eager" */ "/vercel/path0/apps/web-app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorProvider"] */ "/vercel/path0/apps/web-app/src/providers/error-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web-app/src/providers/loading-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/vercel/path0/apps/web-app/src/providers/navigation-events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/apps/web-app/src/providers/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/web-app/src/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UpdateProvider"] */ "/vercel/path0/apps/web-app/src/providers/update-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/packages/ui/src/tooltip.tsx");
