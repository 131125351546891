"use client";

import { useContext, useEffect } from "react";
import { ThemeProvider as NextThemeProvider } from "next-themes";
import { THEME_STORAGE_KEY } from "next.constants.mjs";
import { useShallow } from "zustand/react/shallow";

import { ModuleTypes } from "@vrodex/db";

import { usePreferencesStore } from "~/hooks/use-preferences-store";
import { UserContext } from "./user-provider";

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useContext(UserContext);

  const { setTheme, theme } = usePreferencesStore(
    useShallow((state) => ({
      setTheme: state.setTheme,
      theme: state.theme,
    })),
  );

  useEffect(() => {
    if (!user) return;
    const body = document.body;

    // Callback function to handle changes in the class attribute
    const handleBodyClassChange = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const newClassValue = body.className;

          if (newClassValue.includes("gold")) {
            if (!user.modules.includes(ModuleTypes.gold_pack)) {
              document.body.classList.remove("theme--gold");
            }
          }
        }
      }
    };

    // Create a MutationObserver to watch for changes in the class attribute
    const observer = new MutationObserver(handleBodyClassChange);

    // Configure the observer to monitor attribute changes
    const observerConfig = { attributes: true, attributeFilter: ["class"] };

    // Start observing the body element
    observer.observe(body, observerConfig);

    // Cleanup: Disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [user]); // The empty dependency array ensures this effect runs once

  useEffect(() => {
    if (theme?.includes("dark")) {
      document.documentElement.classList.add("dark");
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "#000000");
    } else {
      document.documentElement.classList.remove("dark");
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "#ffffff");
    }
  }, [theme]);

  useEffect(() => {
    if (!user) return;
    // setTheme(theme.includes("dark") ? "dark" : "light");

    const extra = theme?.split("_");

    if (!extra) return;

    if (extra[1]) {
      if (user.modules.toString().includes(extra[1])) {
        document.body.classList.add(`theme--${extra[1]}`);
      } else {
        document.body.classList.remove("theme--gold");
      }
    } else {
      document.body.classList.remove("theme--gold");
    }
  }, [theme, setTheme, user]);

  return (
    <NextThemeProvider
      attribute="class"
      defaultTheme="system"
      storageKey={THEME_STORAGE_KEY}
      enableSystem={true}
    >
      {children}
    </NextThemeProvider>
  );
};
