"use client";

import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { useShallow } from "zustand/react/shallow";

import { useSessionStore } from "~/hooks/use-session-store";
import { usePathname } from "~/utils/navigation";
import {
  dispatchRouteChangeEvent,
  registerRouteChangeListener,
  unRegisterRouteChangeListener,
} from "~/utils/route-events";
import { useLoading } from "./loading-provider";

export function NavigationEvents() {
  const pathname = usePathname();

  const { setIsLoading } = useLoading();

  const { setMobileNavOpen, setSettingsNavOpen, setSettingsSearch } =
    useSessionStore(
      useShallow((state) => ({
        setMobileNavOpen: state.setMobileNavOpen,
        setSettingsNavOpen: state.setSettingsNavOpen,
        setSettingsSearch: state.setSettingsSearch,
      })),
    );

  const searchParams = useSearchParams();

  useEffect(() => {
    const startEvent = () => {
      setIsLoading(true);
    };

    const completeEvent = () => {
      setIsLoading(false);
      setMobileNavOpen(false);
      setSettingsNavOpen(false);
      setSettingsSearch("");
    };

    registerRouteChangeListener("start", startEvent);

    registerRouteChangeListener("completed", completeEvent);

    return () => {
      unRegisterRouteChangeListener("start", startEvent);
      unRegisterRouteChangeListener("start", completeEvent);
    };
  }, [setIsLoading, setMobileNavOpen, setSettingsNavOpen, setSettingsSearch]);

  useEffect(() => {
    dispatchRouteChangeEvent("completed");
  }, [pathname, searchParams]);

  return null;
}
